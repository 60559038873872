@font-face {
  font-family: "Outsiders_Medium";
  src: local("Outsiders_Medium"),
    url(./font/Outsiders_Medium.otf) format("opentype");
}
body {
  margin: 0;
  font-family: "Outsiders_Medium", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body {
  height: 100%;
  margin: 0;
}

.full-height {
  height: 100%;
  text-align: center;
}
